import { BigNumber } from 'bignumber.js';
import { Texture, Sprite, VideoResource } from 'pixi.js';
import { AdjustmentFilter } from '@pixi/filter-adjustment';
import type TiledMap from 'tiled-tmj-typedefs';

import { ElNotification } from 'element-plus';
import {
  PROVIDER_LOW_GAS_ERROR_CODE,
  MIN_SIZES_SCALE_FACTOR,
  MOBILE_MEDIA_QUERY,
  PROVIDER_GAS_ERROR,
  SUCCEEDED_TRANSACTION_LIFETIME,
  WORLD_BOUNDS_OFFSET,
  NOT_ENOUGH_BNB_TO_PAY_FOR_GAS,
  PROVIDERS,
  ACTION_REJECTED,
  ACTION_REJECTED_SHORT_ERROR
} from '../utils/constants';
import type { TNullable } from '~/types/common';

/**
 * Function that throws an error, either a provided Error object or a new Error with the given message.
 * @param error - The error message or Error object to throw.
 * @throws Always throws an error.
 */
export function throws(error: string | Error): never {
  if (error instanceof Error) {
    throw error; // Throw the provided Error object
  }
  throw new Error(error); // Throw a new Error with the provided error message
}

// Keys for local storage
const hashHistoryKey = 'hashHistory';
const hashHistoryBrokenKey = 'hashHistoryBroken';

// Interface for a hash record
export interface HashRecord {
  hash: string;
  date: number;
  editToSucceedDate?: TNullable<number>;
  desc: string;
  status: string;
  show: boolean;
}

// Utility function: Sleep for a specified number of milliseconds
export const sleep = (ms: number): Promise<void> => new Promise((resolve) => setTimeout(() => resolve(), ms));

/**
 * Function to save a hash record to local storage.
 * @param desc - Description of the hash record.
 * @param hash - Hash value to be saved.
 */
export function saveHashToLocalStorage(desc: string, hash: string) {
  const val = localStorage.getItem(hashHistoryKey);
  let ar;
  if (val !== null) {
    ar = parseJson(val);
  } else {
    ar = [];
  }
  ar.push({ hash, date: +new Date(), desc, status: 'Processing', show: true });
  localStorage.setItem(hashHistoryKey, JSON.stringify(ar));
}

// Function to parse JSON while handling potential errors
function parseJson(value: string) {
  try {
    return JSON.parse(value); // Parse JSON string to object
  } catch (e: unknown) {
    console.error(e);
    localStorage.setItem(hashHistoryBrokenKey, value);
    localStorage.removeItem(hashHistoryKey);
    throw new Error('failed to parse hash history'); // Throw an error for failed parsing
  }
}

// Function to retrieve hash history from local storage
export function getHashHistory(): HashRecord[] {
  const list = localStorage.getItem(hashHistoryKey);

  if (!list) {
    return [];
  } else {
    const newList = cleanupOldSucceededTransactions(parseJson(list));
    localStorage.setItem(hashHistoryKey, JSON.stringify(newList));
    return newList;
  }
}

export function resetHashHistory(): void {
  localStorage.setItem(hashHistoryKey, JSON.stringify([]));
}

export function cleanupOldSucceededTransactions(txnList: HashRecord[]) {
  return txnList.filter((rec: HashRecord) =>
    !rec?.editToSucceedDate ? true : Number(new Date()) - rec.editToSucceedDate < SUCCEEDED_TRANSACTION_LIFETIME
  );
}

/**
 * Function to mark a hash as seen in history.
 * @param hash - Hash value to mark as seen.
 */
export function flagAsSeenHistoryHash(hash: string) {
  const value = localStorage.getItem(hashHistoryKey);
  if (!value) {
    return false;
  } else {
    const list = parseJson(value);
    const newList = list.map((rec: HashRecord) => {
      if (rec.hash === hash) {
        rec.show = false;
      }
      return rec;
    });
    localStorage.setItem(hashHistoryKey, JSON.stringify(newList));
  }
}

/**
 * Function to modify the status of a history hash.
 * @param hash - Hash value to modify status for.
 * @param status - New status to assign to the history hash.
 */
export function modifyHistoryHashStatus(hash: string, status: string) {
  const value = localStorage.getItem(hashHistoryKey);
  if (!value) {
    return false;
  } else {
    const list = parseJson(value);
    const newList = list.map((rec: HashRecord) => {
      if (rec.hash === hash) {
        rec.status = status;
      }
      if (status === 'Done') {
        rec.editToSucceedDate = +new Date();
      }
      return rec;
    });
    localStorage.setItem(hashHistoryKey, JSON.stringify(cleanupOldSucceededTransactions(newList)));
  }
}

// Function to update processing transactions
export function updateProcessingTransactions() {
  const value = localStorage.getItem(hashHistoryKey);
  if (!value) {
    return false;
  } else {
    const list = parseJson(value);
    const newList = list.map((rec: HashRecord) => {
      if (rec.status === 'Processing') {
        rec.show = true;
      }
      return rec;
    });
    localStorage.setItem(hashHistoryKey, JSON.stringify(newList));
  }
}

/**
 * Function to convert an error object to a string message.
 * @param error - The error object or message to be converted.
 * @returns A formatted error message.
 */
export function stringifyError(error: unknown): string {
  if (error && typeof error === 'object' && 'message' in error) {
    // Handle special JSON-RPC errors
    const message = String((error as { message: string }).message);
    const rpcMatch = message.match(/^Internal JSON-RPC error\.\n(\{[\s\S]+\})/);
    if (rpcMatch) {
      try {
        const parsed = JSON.parse(rpcMatch[1]);
        if (parsed.message) {
          return parsed.message;
        }
      } catch (parseError: unknown) {
        console.log(parseError);
      }
    }
    return message;
  } else {
    return String(error);
  }
}

/**
 * Function to format a meaningful decimal value.
 * @param price - The price value to be formatted.
 * @param meaningfulDecimals - The number of meaningful decimals.
 * @returns The formatted decimal value.
 */
export function formatMeaningfulDecimalValue(price: string, meaningfulDecimals = 4): string {
  // Calculate decimals for formatting
  let decimals = 0;
  const pos = price.toString().replace('.', '').toString().search(/[^0.]/);
  const pointPosition = price.toString().search(/[.]/);
  if (pointPosition !== -1 && pointPosition < pos + meaningfulDecimals) {
    decimals = pos - pointPosition + meaningfulDecimals;
  }

  return new BigNumber(price).toFixed(decimals); // Format the value with the calculated decimals
}

/**
 * Function to extract the base name from a URL.
 * @param str - The URL string.
 * @returns The extracted base name.
 */
export function baseName(str: string | undefined) {
  if (!str) {
    return str;
  }
  let base = str.substring(str.lastIndexOf('/') + 1);
  if (base.lastIndexOf('.') !== -1) {
    base = base.substring(0, base.lastIndexOf('.'));
  }
  return base;
}

export function formattedTodayDate() {
  const date = new Date();
  return date.getUTCFullYear() + '-' + (date.getUTCMonth() + 1) + '-' + date.getDate();
}

export function formattedLastMonthDate() {
  const date = new Date(+new Date() - 30 * 86400 * 1000);
  return date.getUTCFullYear() + '-' + (date.getUTCMonth() + 1) + '-' + date.getUTCDate();
}

export function formatNumber(num: number | string, delim = ',') {
  return num.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1' + delim);
}

const clipboardApiFallback = (text: string) => {
  const isIos = navigator.userAgent.match(/ipad|iphone/i);
  const textarea = document.createElement('textarea');

  // create textarea
  textarea.value = text;

  // ios will zoom in on the input if the font-size is < 16px
  textarea.style.fontSize = '20px';
  document.body.appendChild(textarea);

  // select text
  if (isIos) {
    const range = document.createRange();
    range.selectNodeContents(textarea);

    const selection = window.getSelection();
    if (selection?.addRange) {
      selection.removeAllRanges();
      selection.addRange(range);
      textarea.setSelectionRange(0, 999999);
    }
  } else {
    textarea.select();
  }

  // copy selection
  document.execCommand('copy');

  // cleanup
  document.body.removeChild(textarea);
};

export const copyToClipboard = (value: string) => {
  if (!navigator.clipboard) {
    clipboardApiFallback(value);
    ElNotification.success({
      title: 'Copied!',
      message: ''
    });
    return;
  }

  navigator.clipboard.writeText(value);
  ElNotification.success({
    title: 'Copied!',
    message: ''
  });
};

export const copyBlobToClipboard = (canvas: HTMLCanvasElement) => {
  try {
    navigator.clipboard
      .write([
        new ClipboardItem({
          // apple devices do not accept any async code before the .write invocation
          'image/png': (async () => {
            return await new Promise((resolve) => canvas.toBlob(resolve as BlobCallback));
          })()
        })
      ])
      .then(() => {
        ElNotification.success({
          title: 'Copied!',
          message: ''
        });
      });
  } catch (err) {
    console.log(err);
  }
};

export const downloadLink = (name: string, href: string) => {
  try {
    const a = document.createElement('a');
    a.download = name;
    a.href = href;
    document.body.append();
    a.click();
    a.remove();
  } catch (err) {
    console.error(err);
  }
};

/**
 * Function to calculate PIXI elements sizes depend on window size.
 * @param tilemap - The current tilemap.
 * @param wrapperElement - Scene wrapper DOM element.

 * @returns Config object contained PIXI scene sizes.
 */
export const getPixiWorldSizes = (tilemap: TiledMap, wrapperElement: HTMLElement) => ({
  rendererSize: {
    width: document.body?.clientWidth,
    height: wrapperElement.clientHeight
  },
  viewportSizes: {
    screenWidth: document.body?.clientWidth,
    screenHeight: wrapperElement.clientHeight,
    worldWidth: tilemap.width * tilemap.tilewidth - WORLD_BOUNDS_OFFSET,
    worldHeight: (tilemap.height * tilemap.tileheight) / 2 - WORLD_BOUNDS_OFFSET
  },
  clampMaxWidth:
    window.innerWidth > MOBILE_MEDIA_QUERY ? tilemap.width * tilemap.tilewidth - WORLD_BOUNDS_OFFSET : null,
  clampMaxHeight:
    window.innerWidth < MOBILE_MEDIA_QUERY ? (tilemap.height * tilemap.tileheight) / 2 - WORLD_BOUNDS_OFFSET : null,
  clampMinWidth:
    window.innerWidth > MOBILE_MEDIA_QUERY
      ? (tilemap.width * tilemap.tilewidth - WORLD_BOUNDS_OFFSET) / MIN_SIZES_SCALE_FACTOR
      : null,
  clampMinHeight:
    window.innerWidth < MOBILE_MEDIA_QUERY
      ? ((tilemap.height * tilemap.tileheight) / 2 - WORLD_BOUNDS_OFFSET) / MIN_SIZES_SCALE_FACTOR
      : null
});

/**
 * Function to create PIXI containers with weather effects layers.
 * @param rainType - The type of rain video to apply.
 * @param width - Size to stretch the video Sprite.
 * @param height - Size to stretch the video Sprite.

 * @returns Object with weather layers.
 */
export const createWeatherEffectsLayers = async (rainType: number, width: number, height: number) => {
  if (typeof window !== 'undefined') {
    const videoResource = new VideoResource(`/assets/video/rain${rainType}.webm`);
    videoResource.updateFPS = 40;
    // @ts-ignore
    const texture = Texture.from<VideoResource>(videoResource);
    texture.baseTexture.resource.source.loop = true;
    texture.baseTexture.resource.source.muted = true;

    const videoSprite = new Sprite(texture);
    videoSprite.filters = [new AdjustmentFilter({ alpha: 0.2 })];

    videoSprite.width = width;
    videoSprite.height = height;

    const lightningLayer = new Sprite(Texture.WHITE);
    lightningLayer.width = width;
    lightningLayer.height = height;
    lightningLayer.filters = [new AdjustmentFilter({ alpha: 0 })];
    return { videoSprite, lightningLayer };
  }
  return {};
};

export const calculatePercentage = (value: number, total: number, decimalPlaces = 0): number => {
  const percentage = (value / total) * 100;
  const roundedPercentage = percentage.toFixed(decimalPlaces);
  return parseFloat(roundedPercentage);
};

export const getCancelErrorMessage = (error: any, providerKind: string) => {
  const isCoinbase = providerKind.toLowerCase().includes('coinbase');
  const insufficientFunds = 'insufficient funds';

  if (Number(error.code) === PROVIDER_LOW_GAS_ERROR_CODE) {
    return PROVIDER_GAS_ERROR;
  }

  if (error?.action?.includes('estimateGas')) {
    return NOT_ENOUGH_BNB_TO_PAY_FOR_GAS;
  }

  if (isCoinbase && error.message?.includes(insufficientFunds)) {
    return NOT_ENOUGH_BNB_TO_PAY_FOR_GAS;
  }

  if (error.code === ACTION_REJECTED) {
    return ACTION_REJECTED_SHORT_ERROR;
  }

  return stringifyError(error);
};

export const walletIconKeyByName = (name: string) => {
  const lowercasedName = name.toLocaleLowerCase();
  return Object.values(PROVIDERS).find((provider) => lowercasedName.includes(provider));
};

export const formatAddress = (address: string, isFullAddress: boolean = false) => {
  return isFullAddress
    ? address.substring(0, 2) + address.substring(2).toUpperCase()
    : address.substring(0, 2) +
        address.substring(2, 6).toUpperCase() +
        '...' +
        address.substring(address.length - 4).toUpperCase();
};
