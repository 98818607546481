import { default as dashboardKIhr9Z9Xg1Meta } from "/workspace/pages/dashboard.vue?macro=true";
import { default as index9S03gOyIVcMeta } from "/workspace/pages/index.vue?macro=true";
import { default as marketplaceOHXB2te2S9Meta } from "/workspace/pages/marketplace.vue?macro=true";
import { default as referrallfm4JwuN6hMeta } from "/workspace/pages/referral.vue?macro=true";
import { default as storagemk6Oq1lGyIMeta } from "/workspace/pages/storage.vue?macro=true";
import { default as storyhm6x9597GQMeta } from "/workspace/pages/story.vue?macro=true";
export default [
  {
    name: dashboardKIhr9Z9Xg1Meta?.name ?? "dashboard",
    path: dashboardKIhr9Z9Xg1Meta?.path ?? "/dashboard",
    meta: dashboardKIhr9Z9Xg1Meta || {},
    alias: dashboardKIhr9Z9Xg1Meta?.alias || [],
    redirect: dashboardKIhr9Z9Xg1Meta?.redirect,
    component: () => import("/workspace/pages/dashboard.vue").then(m => m.default || m)
  },
  {
    name: index9S03gOyIVcMeta?.name ?? "index",
    path: index9S03gOyIVcMeta?.path ?? "/",
    meta: index9S03gOyIVcMeta || {},
    alias: index9S03gOyIVcMeta?.alias || [],
    redirect: index9S03gOyIVcMeta?.redirect,
    component: () => import("/workspace/pages/index.vue").then(m => m.default || m)
  },
  {
    name: marketplaceOHXB2te2S9Meta?.name ?? "marketplace",
    path: marketplaceOHXB2te2S9Meta?.path ?? "/marketplace",
    meta: marketplaceOHXB2te2S9Meta || {},
    alias: marketplaceOHXB2te2S9Meta?.alias || [],
    redirect: marketplaceOHXB2te2S9Meta?.redirect,
    component: () => import("/workspace/pages/marketplace.vue").then(m => m.default || m)
  },
  {
    name: referrallfm4JwuN6hMeta?.name ?? "referral",
    path: referrallfm4JwuN6hMeta?.path ?? "/referral",
    meta: referrallfm4JwuN6hMeta || {},
    alias: referrallfm4JwuN6hMeta?.alias || [],
    redirect: referrallfm4JwuN6hMeta?.redirect,
    component: () => import("/workspace/pages/referral.vue").then(m => m.default || m)
  },
  {
    name: storagemk6Oq1lGyIMeta?.name ?? "storage",
    path: storagemk6Oq1lGyIMeta?.path ?? "/storage",
    meta: storagemk6Oq1lGyIMeta || {},
    alias: storagemk6Oq1lGyIMeta?.alias || [],
    redirect: storagemk6Oq1lGyIMeta?.redirect,
    component: () => import("/workspace/pages/storage.vue").then(m => m.default || m)
  },
  {
    name: storyhm6x9597GQMeta?.name ?? "story",
    path: storyhm6x9597GQMeta?.path ?? "/story",
    meta: storyhm6x9597GQMeta || {},
    alias: storyhm6x9597GQMeta?.alias || [],
    redirect: storyhm6x9597GQMeta?.redirect,
    component: () => import("/workspace/pages/story.vue").then(m => m.default || m)
  }
]